import { CategoryItemShow } from "../types/CategoryItemShow";
import { AssetImage } from "./AssetImage";

const linkStyle = {
  color: "white",
  textDecoration: "none",
};

interface Props {
  categoryItemShow: CategoryItemShow;
  onClick: () => void;
  showCard: boolean;
}

export function TooltipCard({ categoryItemShow, onClick, showCard }: Props) {
  const { fileName } = categoryItemShow.category;
  const {
    maxSqFt,
    minSqFt,
    hours,
    menuHref,
    open24Hours,
    leasable,
    title,
    leasingInfo,
    address,
    description,
  } = categoryItemShow.show;
  const cardInfo = categoryItemShow.show;

  const renderSqft = () => {
    if (maxSqFt && minSqFt && maxSqFt > 0 && minSqFt > 0) {
      return (
        <span style={{ width: "75%" }}>
          {maxSqFt} - {minSqFt} sq ft.
        </span>
      );
    } else {
      return null;
    }
  };

  const hasHoursOnCurrentDay = () => {
    if (hours) {
      const day = new Date().getDay();
      const currentDayHours = hours[day];
      if (currentDayHours && currentDayHours.hours.length > 0) {
        return true;
      }
    }

    return false;
  };

  const renderHours = () => {
    if (hasHoursOnCurrentDay() && hours) {
      const day = new Date().getDay();
      const currentDayHours = hours[day];
      if (currentDayHours !== undefined) {
        return (
          <span>
            {currentDayHours.hours.map((range: string) => range).join(" & ")}
          </span>
        );
      }
      return null;
    } else if (open24Hours === true) {
      return <span> Open 24 Hours</span>;
    }
    return null;
  };

  const renderBtn = () => {
    if (minSqFt) {
      return (
        <button style={buttonStyle}>
          <a
            href="https://www.aspiriakc.com/contact-us"
            style={linkStyle}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Contact Agent
          </a>
        </button>
      );
    } else if (menuHref) {
      return (
        <button style={buttonStyle}>
          <a style={linkStyle} target="_blank" href={menuHref} rel="noreferrer">
            {" "}
            Order Online{" "}
          </a>{" "}
        </button>
      );
    } else {
      return <div style={{ width: "100%", height: "30px" }}></div>;
    }
  };

  const renderItem = () => {
    if (!leasable) {
      return (
        <div style={menuItemWrapperStyle}>
          <div style={{ position: "relative" }}>
            <span style={{ ...closeStyle, color: "black" }} onClick={onClick}>
              <i className="fas fa-times" />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 16px",
              marginTop: "30px",
            }}
          >
            {fileName && <AssetImage style={iconStyle} fileName={fileName} />}
            <h1 style={headingStyle}>{title}</h1>
          </div>

          <div style={{ margin: "0px 16px" }}>
            {description && <p style={textStyle}>{description}</p>}
            <ul style={listStyle}>
              {address && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Marker.svg" />
                  <span>{address}</span>
                </li>
              )}
              {hours && hours.length !== 0 && hours[new Date().getDay()] && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Clock.svg" />
                  {renderHours()}
                </li>
              )}
              {cardInfo.orderOptions && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Squares.svg" />
                  <span>{cardInfo.orderOptions}</span>
                </li>
              )}
              {cardInfo.parking && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Parking.svg" />

                  <span>{cardInfo.parking}</span>
                </li>
              )}
              <li style={{ ...listItemStyle, display: "flex" }}>
                <div style={{ width: "18%" }}></div>

                {renderSqft()}
              </li>

              {cardInfo.menuHref && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Clipboard.svg" />
                  <span>
                    <a
                      href={cardInfo.menuHref}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Menu
                    </a>
                  </span>
                </li>
              )}
              {cardInfo.website && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Globe.svg" />
                  <span>
                    <a href={cardInfo.website} target="_blank" rel="noreferrer">
                      Website
                    </a>
                  </span>
                </li>
              )}
            </ul>
            {renderBtn()}
          </div>
        </div>
      );
    } else {
      return (
        <div style={menuItemWrapperStyle}>
          <div style={{ position: "relative" }}>
            <span style={{ ...closeStyle, color: "black" }} onClick={onClick}>
              <i className="fas fa-times" />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 16px",
              marginTop: "30px",
            }}
          >
            {fileName && <AssetImage style={iconStyle} fileName={fileName} />}
            <h1 style={headingStyle}>{cardInfo.title}</h1>
          </div>
          <div style={{ margin: "0px 16px" }}>
            <p style={textStyle}>{cardInfo.description}</p>
            <ul style={listStyle}>
              {cardInfo.address && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Marker.svg" />
                  <span>{cardInfo.address}</span>
                </li>
              )}
              {cardInfo.orderOptions && (
                <li style={{ ...listItemStyle }}>
                  <span>{cardInfo.type}</span>
                </li>
              )}
              {cardInfo.parking && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Parking.svg" />
                  <span>
                    {cardInfo.parking} <br />
                  </span>
                </li>
              )}
              {minSqFt && maxSqFt && (
                <li style={{ ...listItemStyle, display: "flex" }}>
                  <div style={{ width: "18%" }}></div>
                  {minSqFt} sq. ft - {maxSqFt} sq. ft
                </li>
              )}
              {leasingInfo && (
                <li style={{ ...listItemStyle }}>
                  <AssetImage style={listImageStyle} fileName="Download.svg" />
                  <span>
                    <a
                      href={leasingInfo}
                      style={{ color: "#005EE3", textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download PDF
                    </a>
                  </span>
                </li>
              )}
            </ul>
            {renderBtn()}
          </div>
        </div>
      );
    }
  };

  const menuItemWrapperStyle = {
    color: "black",
    backgroundColor: "white",
    padding: "0px",
    fontFamily: "'Roboto', sans-serif",
    width: "288px",
    height: "auto",
    display: "flex" as const,
    position: "absolute" as const,
    bottom: "20px",
    left: "100%",
    transform: "translateX(20px)",
    transformOrigin: "left top",
    flexDirection: "column" as const,
    overflowY: "visible" as const,
    zIndex: 24,
    opacity: showCard ? ".95" : "0",
    transition: "opacity 200ms",
    boxShadow: "0px 24px 24px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
  };

  const iconStyle = {
    width: "34px",
    height: "34px",
    margin: "0px 8px",
    overflow: "visible",
  };

  const headingStyle = {
    fontSize: "21px",
    lineHeight: "1.2em",
  };

  const textStyle = {
    margin: "0px 8px",
  };

  const buttonStyle = {
    backgroundColor: "#005EE3",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "4px 12px",
    fontSize: "16px",
    margin: "16px 8px",
    height: "32px",
    fontFamily: "'Roboto', sans-serif",
  };

  const listItemStyle = {
    fontSize: "14px",
    display: "flex" as const,
    flexDirection: "row" as const,
    alignItems: "center" as const,
    padding: "2px 0px 0px",
    fontFamily: "'Roboto', sans-serif",
  };

  const listStyle = {
    lineHeight: "21px",
    padding: "0px",
    margin: "8px 0px",
  };

  const listImageStyle = {
    width: "20px",
    height: "22px",
    margin: "0px 12px",
  };

  const closeStyle = {
    position: "absolute" as const,
    top: "12px",
    right: "12px",
    fontSize: "18px",
    fontWeight: 300,
    color: "white",
    textAlign: "center" as const,
    cursor: "pointer" as const,
  };

  return <div id="tooltip">{renderItem()}</div>;
}
