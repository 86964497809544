import { useMenuState } from "../../hooks/useMenuState";
import { Category } from "../../types/Category";
import { CategoryItemShow } from "../../types/CategoryItemShow";
import { MenuItem } from "../MenuItem";
import "./styles.css";

interface Props {
  menuState: ReturnType<typeof useMenuState>;
  categories: Category[];
}

export function Menu({ menuState, categories }: Props) {
  const onClickCategory = (categoryClicked: Category): void => {
    menuState.setCurrent((current) => ({
      selectedCategory:
        current.selectedCategory?.type === categoryClicked.type
          ? null
          : categoryClicked,
      selectedCategoryItem: null,
    }));
  };

  const onClickCategoryItem = (
    selectedCategoryItem: CategoryItemShow,
  ): void => {
    menuState.setCurrent((current) => ({
      ...current,
      selectedCategoryItem,
    }));
  };

  return (
    <div className="Menu">
      <div className="Menu-header">
        <h1>Aspiria Facilities & Amenities</h1>
      </div>
      <div className="Menu-body">
        {categories.map((category) => {
          return (
            <MenuItem
              key={category.type}
              data={category}
              onClickCategoryItem={onClickCategoryItem}
              onClickCategory={onClickCategory}
              aMenuIsActive={Boolean(menuState.current.selectedCategory)}
              isActive={
                menuState.current.selectedCategory?.type === category.type
              }
            />
          );
        })}
      </div>
    </div>
  );
}
