import { ReactComponent as ATMs } from "../../assets/images/AmenitiesIconsATMs.svg";
import { ReactComponent as Studio } from "../../assets/images/AmenitiesIconsAspiriaStudioIcon.svg";
import { ReactComponent as BikeParking } from "../../assets/images/AmenitiesIconsBikeParking.svg";
import { ReactComponent as Cafe } from "../../assets/images/AmenitiesIconsCafe.svg";
import { ReactComponent as ConferenceCenters } from "../../assets/images/AmenitiesIconsConferenceCenters.svg";
import { ReactComponent as Dining } from "../../assets/images/AmenitiesIconsDining.svg";
import { ReactComponent as Fitness } from "../../assets/images/AmenitiesIconsFitness.svg";
import { ReactComponent as Leasable } from "../../assets/images/AmenitiesIconsLeasingSpaceAvailable.svg";
import { ReactComponent as ManagementOffice } from "../../assets/images/AmenitiesIconsManagementOffice.svg";
import { ReactComponent as OutdoorSpace } from "../../assets/images/AmenitiesIconsOutdoorSpace.svg";
import { ReactComponent as Parking } from "../../assets/images/AmenitiesIconsParking.svg";
import { ReactComponent as RetailandServices } from "../../assets/images/AmenitiesIconsRetailandServices.svg";
import { ReactComponent as SmokingArea } from "../../assets/images/AmenitiesIconsSmokingArea.svg";
//import { AmenitySlug } from "../../types/AmenityType"
import { AreaPosition } from "../../types/AreaPosition";
import { CategoryType } from "../../types/Category";
import "./styles.css";

interface Props {
  type: CategoryType; // | AmenitySlug
  iconBar: AreaPosition["iconBar"];
}

export function IconCategory({ type, iconBar }: Props) {
  return (
    <div
      className="IconCategory"
      style={{ transform: `rotate(-${iconBar.rotation}deg)` }}
    >
      {getIcon(type)}
    </div>
  );
}

function getIcon(type: CategoryType) {
  switch (type) {
    case CategoryType["atms"]:
      return <ATMs />;
    case CategoryType["bike-sharing"]:
      return <BikeParking />;
    case CategoryType["coffee-cafe"]:
      return <Cafe />;
    case CategoryType["conference-center"]:
      return <ConferenceCenters />;
    case CategoryType["dining"]:
      return <Dining />;
    case CategoryType["health-fitness"]:
      return <Fitness />;
    case CategoryType["management-office"]:
      return <ManagementOffice />;
    case CategoryType["outdoor-space"]:
      return <OutdoorSpace />;
    case CategoryType["retail-services"]:
      return <RetailandServices />;
    case CategoryType["leasing"]:
      return <Leasable />;
    case CategoryType["parking"]:
      return <Parking />;
    case CategoryType["smoking-area"]:
      return <SmokingArea />;
    case CategoryType["studio"]:
      return <Studio />;
    default:
      return null;
  }
}
