export function isAmenityByCategoryName(categoryName: string) {
  switch (categoryName) {
    case "main_office":
      return true;
    case "coffee":
      return true;
    case "fitness":
      return true;
    case "dining":
      return true;
    case "retail":
      return true;
    case "atm":
      return true;
    case "conference":
      return true;
    case "studio":
      return true;
    default:
      return false;
  }
}

export function getCollectionTypeFromCategoryName(categoryName: string) {
  switch (categoryName) {
    case "main_office":
      return "management-office";
    case "coffee":
      return "coffee-cafe";
    case "fitness":
      return "health-fitness";
    case "dining":
      return "dining";
    case "retail":
      return "retail-services";
    case "atm":
      return "atms";
    case "conference":
      return "conference-center";
    case "studio":
      return "studio";
    default:
      return "";
  }
}
