import classNames from "classnames";
import { useContext } from "react";
import { MenuMobileContext } from "../../contexts/MenuMobileContext";
import { useMenuState } from "../../hooks/useMenuState";
import { Category } from "../../types/Category";
import { AssetImage } from "../AssetImage";
import { SubMenuItem } from "../SubMenuItem";
import { CategoriesItem } from "./components/CategoriesItem";
import { Item } from "./components/Item";
import "./styles.css";

type MenuType = "CATEGORIES" | "CATEGORY" | "ITEM";

interface Props {
  categories: Category[];
  menuState: ReturnType<typeof useMenuState>;
  zoomLevel: number;
  zoomLevelMax: number;
  handleZoomIn: (e: React.MouseEvent<HTMLButtonElement>) => void;
  zoomLevelMin: number;
  handleZoomOut: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MenuMobile = ({
  categories,
  menuState,
  zoomLevel,
  zoomLevelMax,
  handleZoomIn,
  zoomLevelMin,
  handleZoomOut,
}: Props) => {
  const { state, dispatch } = useContext(MenuMobileContext);

  const onClickHeaderIconLeft = (type: MenuType) => {
    if (type === "CATEGORY") {
      dispatch({ type: "CATEGORIES_ACTIVE" });
      menuState.reset();
    }
    if (type === "ITEM") {
      dispatch({ type: "CATEGORY_PARTIAL" });
      menuState.setCurrent((current) => ({
        ...current,
        selectedCategoryItem: null,
      }));
    }
  };

  const onClickHeaderIconRight = (type: MenuType) => {
    if (type === "CATEGORIES") {
      dispatch((current) =>
        current.type !== "CATEGORIES_ACTIVE"
          ? { type: "CATEGORIES_ACTIVE" }
          : { type: "CATEGORIES_INACTIVE" },
      );
      menuState.reset();
    }
    if (type === "CATEGORY") {
      dispatch((current) =>
        current.type !== "CATEGORY_FULL"
          ? { type: "CATEGORY_FULL" }
          : { type: "CATEGORY_PARTIAL" },
      );
    }
    if (type === "ITEM") {
      dispatch((current) =>
        current.type !== "ITEM_FULL"
          ? { type: "ITEM_FULL" }
          : { type: "ITEM_PARTIAL" },
      );
    }
  };

  const onClickCategoriesItem = (category: Category) => {
    menuState.setCurrent((current) => ({
      ...current,
      selectedCategory: category,
    }));
    dispatch({ type: "CATEGORY_PARTIAL" });
  };

  const onClickCategoryItem = (categoryItem: Category["items"][number]) => {
    menuState.setCurrent((current) => ({
      ...current,
      selectedCategoryItem: categoryItem,
    }));
    dispatch({ type: "ITEM_PARTIAL" });
  };

  const MenuMobilePanelCategories = classNames("MenuMobile-panel -categories", {
    "-active -full": state.type === "CATEGORIES_ACTIVE",
  });

  const MenuMobilePanelCategory = classNames("MenuMobile-panel -category", {
    "-active -full": state.type === "CATEGORY_FULL",
    "-active -partial": state.type === "CATEGORY_PARTIAL",
  });

  const MenuMobilePanelItem = classNames("MenuMobile-panel -item", {
    "-active -full": state.type === "ITEM_FULL",
    "-active -partial": state.type === "ITEM_PARTIAL",
  });

  const makeZoomControls = (type: MenuType) => (
    <div className={`Zoom -${type}`}>
      <button
        disabled={zoomLevel === zoomLevelMax}
        onClick={handleZoomIn}
        className="on-map-buttons"
      >
        <i className="fas fa-plus"></i>
      </button>
      <button
        disabled={zoomLevel === zoomLevelMin}
        onClick={handleZoomOut}
        className="on-map-buttons"
      >
        <i className="fas fa-minus"></i>
      </button>
    </div>
  );

  return (
    <div className="MenuMobile">
      {/* This div is used for available space calculations */}
      <div className="MenuMobile-panel -availableSpace"></div>
      <div className={MenuMobilePanelCategories}>
        {makeZoomControls("CATEGORIES")}
        <div
          className="MenuMobile-panel-header"
          onClick={() => onClickHeaderIconRight("CATEGORIES")}
        >
          <div className="MenuMobile-panel-header-title">
            Aspiria Facilities & Amenities
          </div>
          <div className="MenuMobile-panel-header-icon-right">
            <i className="fas fa-chevron-up"></i>
          </div>
        </div>
        <div className="MenuMobile-panel-body">
          <ul>
            {categories.map((category) => (
              <CategoriesItem
                key={category.type}
                category={category}
                onClick={onClickCategoriesItem}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className={MenuMobilePanelCategory}>
        {makeZoomControls("CATEGORY")}
        <div className="MenuMobile-panel-header">
          <div
            className="MenuMobile-panel-header-icon-left"
            onClick={() => onClickHeaderIconLeft("CATEGORY")}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
          {menuState.current.selectedCategory?.fileName && (
            <AssetImage
              fileName={menuState.current.selectedCategory.fileName}
            />
          )}
          <div className="MenuMobile-panel-header-title">
            {menuState.current.selectedCategory?.title}
          </div>
          <div
            className="MenuMobile-panel-header-icon-right"
            onClick={() => onClickHeaderIconRight("CATEGORY")}
          >
            <i className="fas fa-chevron-up"></i>
          </div>
        </div>
        <div className="MenuMobile-panel-body">
          <ul>
            {menuState.current.selectedCategory?.items.map((categoryItem) => (
              <li key={categoryItem.item.id}>
                <SubMenuItem
                  categoryItemShow={categoryItem}
                  fileName=""
                  subMenuClick={onClickCategoryItem}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={MenuMobilePanelItem}>
        {makeZoomControls("ITEM")}
        <div className="MenuMobile-panel-header">
          <div
            className="MenuMobile-panel-header-icon-left"
            onClick={() => onClickHeaderIconLeft("ITEM")}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
          {menuState.current.selectedCategory?.fileName && (
            <AssetImage
              fileName={menuState.current.selectedCategory.fileName}
            />
          )}
          <div className="MenuMobile-panel-header-title">
            {menuState.current.selectedCategoryItem?.item.display}
          </div>
          <div
            className="MenuMobile-panel-header-icon-right"
            onClick={() => onClickHeaderIconRight("ITEM")}
          >
            <i className="fas fa-chevron-up"></i>
          </div>
        </div>
        <div className="MenuMobile-panel-body">
          {menuState.current.selectedCategoryItem?.show && (
            <Item categoryItemShow={menuState.current.selectedCategoryItem} />
          )}
        </div>
      </div>
    </div>
  );
};
