import { Area } from "../types/Area";
import { AreaPosition, AreaPositionMap } from "../types/AreaPosition";
import { CategoryAPIType } from "../types/Category";

export const areaPositions: AreaPositionMap = {
  building_6050: {
    left: 1456,
    top: 686,
    iconBar: {
      rotation: 0,
      left: -10,
      top: -10,
    },
  },
  building_6100: {
    left: 1401,
    top: 810,
    iconBar: {
      rotation: 90,
      left: -25,
      top: 0,
    },
  },
  building_6130: {
    left: 1496,
    top: 994,
    iconBar: {
      rotation: 90,
      left: -18,
      top: -20,
    },
  },
  building_6160: {
    left: 1426,
    top: 1126,
    iconBar: {
      rotation: 0,
      left: -38,
      top: -8,
    },
  },
  building_6180: {
    left: 1387,
    top: 999,
    iconBar: {
      rotation: 90,
      left: -5,
      top: 10,
    },
  },
  building_6200: {
    left: 1310,
    top: 1158,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  building_6220: {
    left: 1394,
    top: 1241,
    iconBar: {
      rotation: 0,
      left: 0,
      top: -5,
    },
  },
  building_6240: {
    left: 1581,
    top: 1288,
    iconBar: {
      rotation: 90,
      left: -3,
      top: 15,
    },
  },
  building_6300: {
    left: 1299,
    top: 1309,
    iconBar: {
      rotation: 90,
      left: 10,
      top: 10,
      styles: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
      },
    },
  },
  building_6330: {
    left: 1199,
    top: 1309,
    iconBar: {
      rotation: 90,
      left: -11,
      top: 15,
    },
  },
  building_6360: {
    left: 1157,
    top: 1138,
    iconBar: {
      rotation: 90,
      left: 22,
      top: 0,
    },
  },
  building_6400: {
    left: 1255,
    top: 1100,
    iconBar: {
      rotation: 0,
      left: 20,
      top: -5,
    },
  },
  building_6450: {
    left: 1211,
    top: 995,
    iconBar: {
      rotation: 90,
      left: -19,
      top: 16,
    },
  },
  building_6480: {
    left: 1292,
    top: 901,
    iconBar: {
      rotation: 90,
      left: 17,
      top: 32,
    },
  },
  building_6500: {
    left: 1285,
    top: 736,
    iconBar: {
      rotation: 90,
      left: -18,
      top: 24,
    },
  },
  building_6550: {
    left: 1350,
    top: 630,
    iconBar: {
      rotation: 90,
      left: 20,
      top: 0,
    },
  },
  building_11500: {
    left: 1460,
    top: 522,
    iconBar: {
      rotation: 90,
      left: -30,
      top: 10,
    },
  },
  building_11501: {
    left: 1557,
    top: 591,
    iconBar: {
      rotation: 90,
      left: -10,
      top: 20,
    },
  },
  garage_a: {
    left: 1617,
    top: 1002,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_b: {
    left: 1561,
    top: 903,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_c: {
    left: 1572,
    top: 785,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_d: {
    left: 1620,
    top: 715,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_e: {
    left: 1671,
    top: 637,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  lot_g: {
    left: 1344,
    top: 470,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_h: {
    left: 1245,
    top: 559,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_i: {
    left: 1208,
    top: 660,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_j: {
    left: 1149,
    top: 785,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_k: {
    left: 1081,
    top: 892,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_l: {
    left: 994,
    top: 1138,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_m: {
    left: 1015,
    top: 1227,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_n: {
    left: 1016,
    top: 1307,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_o: {
    left: 1004,
    top: 1397,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  garage_p: {
    left: 1412,
    top: 1334,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  circle_drive: {
    left: 1802,
    top: 1234,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  amphitheater_at_aspiria: {
    left: 1342,
    top: 811,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  town_square: {
    left: 1257,
    top: 1039,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  town_square_circle_drive: {
    left: 1291,
    top: 994,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  satchel_paige_field: {
    left: 1696,
    top: 1257,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  patio_at_the_hub: {
    left: 1254,
    top: 1211,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  relaxation_courtyard: {
    left: 1435,
    top: 1036,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  the_backyard: {
    left: 1254,
    top: 1156,
    iconBar: {
      rotation: 0,
      left: 0,
      top: -30,
    },
  },
  "6050_6100_circle_drive": {
    left: 1472,
    top: 759,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6300_6330_circle_drive": {
    left: 1266,
    top: 1353,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6500_6550_circle_drive": {
    left: 1306,
    top: 699,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  tennis_courts: {
    left: 1818,
    top: 1265,
    iconBar: {
      rotation: 90,
      left: 0,
      top: 0,
    },
  },
  Garage_P_bikekc_hub: {
    left: 1550,
    top: 1437,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6050_bikekc_hub": {
    left: 1502,
    top: 759,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6160_bikekc_hub": {
    left: 1418,
    top: 1172,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6330_bikekc_hub": {
    left: 1250,
    top: 1357,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6360_bikekc_hub": {
    left: 1191,
    top: 1238,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6500_bikekc_hub": {
    left: 1318,
    top: 763,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "11500_11501_bikekc_hub": {
    left: 1584,
    top: 600,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  town_square_bikekc_hub: {
    left: 1326,
    top: 1044,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "11500_smoking_area": {
    left: 1560,
    top: 525,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "11501_smoking_area": {
    left: 1650,
    top: 635,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6050_smoking_area": {
    left: 1519,
    top: 766,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6100_smoking_area": {
    left: 1422,
    top: 800,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6160_smoking_area": {
    left: 1543,
    top: 1125,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6220_6240_smoking_area": {
    left: 1560,
    top: 1300,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6300_6330_smoking_area": {
    left: 1278,
    top: 1320,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6500_6480_smoking_area": {
    left: 1320,
    top: 887,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6450_smoking_area": {
    left: 1270,
    top: 980,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
  "6180_smoking_area": {
    left: 1428,
    top: 982,
    iconBar: {
      rotation: 0,
      left: 0,
      top: 0,
    },
  },
};

const iconBarDefault: AreaPosition["iconBar"] = {
  left: 0,
  top: 0,
  rotation: 0,
};

export const getAreaPosition = (area: Area): AreaPosition | null => {
  switch (area.category_name) {
    case CategoryAPIType.BUILDINGS: {
      const areaPosition = areaPositions[area.name];
      const left = ((areaPosition?.left ?? 1) / 2800 ?? 0) * 100;
      const top = ((areaPosition?.top ?? 1) / 1981 ?? 0) * 100;
      return {
        left,
        top,
        iconBar: areaPosition ? areaPosition.iconBar : iconBarDefault,
      };
    }
    case CategoryAPIType.PARKING: {
      const areaPosition = areaPositions[area.name];
      const left = ((areaPosition?.left ?? 1) / 2800 ?? 0) * 100;
      const top = ((areaPosition?.top ?? 1) / 1981 ?? 0) * 100;
      return {
        left,
        top,
        iconBar: areaPosition ? areaPosition.iconBar : iconBarDefault,
      };
    }
    case CategoryAPIType.OUTDOOR: {
      const areaPosition = areaPositions[area.name];
      const left = ((areaPosition?.left ?? 1) / 2800 ?? 0) * 100;
      const top = ((areaPosition?.top ?? 1) / 1981 ?? 0) * 100;
      return {
        left,
        top,
        iconBar: areaPosition ? areaPosition.iconBar : iconBarDefault,
      };
    }
    case CategoryAPIType.SMOKING: {
      const areaPosition = areaPositions[area.name];
      const left = ((areaPosition?.left ?? 1) / 2800 ?? 0) * 100;
      const top = ((areaPosition?.top ?? 1) / 1981 ?? 0) * 100;
      return {
        left,
        top,
        iconBar: areaPosition ? areaPosition.iconBar : iconBarDefault,
      };
    }
    case CategoryAPIType.BIKE_PARKING: {
      const areaPosition = areaPositions[area.name];
      const left = ((areaPosition?.left ?? 1) / 2800 ?? 0) * 100;
      const top = ((areaPosition?.top ?? 1) / 1981 ?? 0) * 100;
      return {
        left,
        top,
        iconBar: areaPosition ? areaPosition.iconBar : iconBarDefault,
      };
    }
    default:
      return null;
  }
};
