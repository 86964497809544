import { Building } from "../types/Building";

export function formatBuildingDisplay(buildings: Building[]) {
  let display = buildings.map((building) => building.display).join("/");

  if (buildings.length > 1) {
    display += " Buildings";
  } else if (buildings.length === 1) {
    display += " Building";
  } else {
    display += "";
  }

  return display;
}
