import axios from "axios";
import { useEffect, useState } from "react";
import { OutdoorSpace } from "../types/OutdoorSpace";

export const useOutdoorSpaces = () => {
  const [outdoorSpaces, setOutdoorSpaces] = useState<OutdoorSpace[]>([]);

  const nodeServerBaseUrl =
    process.env.REACT_APP_NODE_SERVER_BASE_URL ||
    "https://aspiria-prod-api.azurewebsites.net/api/v1";

  useEffect(() => {
    axios
      .get(`${nodeServerBaseUrl}/outdoor-space/`)
      .then((response) => {
        setOutdoorSpaces(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nodeServerBaseUrl]);

  return {
    outdoorSpaces,
  };
};
