import { Amenity } from "../types/Amenity";
import { Building } from "../types/Building";
import { Category, CategoryAPIType } from "../types/Category";
import { CategoryItem } from "../types/CategoryItem";
import { CategoryItemShow } from "../types/CategoryItemShow";
import { OutdoorSpace } from "../types/OutdoorSpace";
import { Parking } from "../types/Parking";
import { isAmenityByCategoryName } from "./amenityUtil";
import { formatBuildingDisplay } from "./buildingUtil";

interface Props {
  category: Category;
  item: CategoryItem;
}

export const categoryItemToShow = ({
  category,
  item,
}: Props): CategoryItemShow => {
  const c = item;
  let show: CategoryItemShow["show"] = {
    id: c.id,
    open24Hours: false,
    isOpen: false,
    title: c.display,
  };

  if (isAmenityByCategoryName(c.category_name)) {
    const amenity = c as Amenity;
    show = {
      id: amenity.id,
      buildings: formatBuildingDisplay(amenity.buildings),
      floor: amenity.floor ? amenity.floor.toString() : "",
      hours: amenity.hours,
      menuHref: amenity.menulink,
      isOpen: amenity.is_open,
      open24Hours: amenity.all_hours,
      parking:
        "Parking: " +
        amenity.parking.map((parking) => parking.display).join(", "),
      title: amenity.display,
      orderOptions: amenity.order_options.map((oo) => oo.display).join(", "),
      website: amenity.website,
      description: amenity.description,
      address: amenity.address,
    };
  }
  if (c.category_name === CategoryAPIType.BUILDINGS) {
    const building = c as Building;
    show = {
      id: building.id,
      type: building.types.map((type) => type.display).join(", "),
      fileName: building.leasing_information,
      open24Hours: false,
      isOpen: false,
      parking:
        "Parking: " +
        building.parking.map((parking) => parking.display).join(", "),
      title: building.display,
      minSqFt: building.min_sq_ft,
      maxSqFt: building.max_sq_ft,
      leasable: building.is_leasable,
      leasingInfo: building.leasing_information,
      description: building.description,
      squareFootage: `${building.min_sq_ft} sq. ft - ${building.max_sq_ft} sq. ft`,
    };
  }
  if (c.category_name === CategoryAPIType.OUTDOOR) {
    const outdoor = c as OutdoorSpace;
    show = {
      id: outdoor.id,
      open24Hours: true,
      isOpen: true,
      parking:
        "Parking: " +
        outdoor.parking.map((parking) => parking.display).join(", "),
      buildings: formatBuildingDisplay(outdoor.buildings),
      title: outdoor.display,
    };
  }
  if (c.category_name === CategoryAPIType.BIKE_PARKING) {
    const bike_parking = c as Amenity;
    show = {
      id: bike_parking.id,
      open24Hours: true,
      isOpen: true,
      parking:
        "Parking: " +
        bike_parking.parking.map((parking) => parking.display).join(", "),
      buildings: formatBuildingDisplay(bike_parking.buildings),
      title: bike_parking.display,
    };
  }
  if (c.category_name === CategoryAPIType.SMOKING) {
    const outdoor = c as OutdoorSpace;
    show = {
      id: outdoor.id,
      open24Hours: true,
      isOpen: true,
      parking:
        "Parking: " +
        outdoor.parking.map((parking) => parking.display).join(", "),
      buildings: formatBuildingDisplay(outdoor.buildings),
      title: outdoor.display,
    };
  }
  if (c.category_name === CategoryAPIType.PARKING) {
    const parking = c as Parking;
    show = {
      id: parking.id,
      open24Hours: true,
      isOpen: true,
      buildings: formatBuildingDisplay(parking.buildings),
      title: `Parking: ${parking.display}`,
    };
  }
  return { show, category, item };
};
