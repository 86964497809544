import { Category } from "../../../../types/Category";
import { AssetImage } from "../../../AssetImage";
import "./styles.css";

interface Props {
  category: Category;
  onClick: (category: Category) => void;
}

export const CategoriesItem = ({ category, onClick }: Props) => {
  return (
    <li className="CategoriesItem" onClick={() => onClick(category)}>
      <div className="CategoriesItem-icon-left">
        <AssetImage fileName={category.fileName} />
      </div>
      <div className="CategoriesItem-title">{category.title}</div>
      <div className="CategoriesItem-icon-right">
        <i className="fas fa-chevron-right"></i>
      </div>
    </li>
  );
};
