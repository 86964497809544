import { CategoryItemShow } from "./CategoryItemShow";

export enum CategoryAPIType {
  FITNESS = "fitness",
  PARKING = "parking",
  RETAIL = "retail",
  COFFEE = "coffee",
  BUILDINGS = "buildings",
  OUTDOOR = "outdoor",
  ATM = "atm",
  BIKE_PARKING = "bike_parking",
  MAIN_OFFICE = "main_office",
  DINING = "dining",
  CONFERENCE = "conference",
  SMOKING = "smoking",
  STUDIO = "studio",
}

export enum CategoryType {
  "bike-sharing" = "bike-sharing",
  "outdoor-space" = "outdoor-space",
  "management-office" = "management-office",
  "atms" = "atms",
  "health-fitness" = "health-fitness",
  "retail-services" = "retail-services",
  "dining" = "dining",
  "conference-center" = "conference-center",
  "coffee-cafe" = "coffee-cafe",
  "parking" = "parking",
  "leasing" = "leasing",
  "smoking-area" = "smoking-area",
  "studio" = "studio",
}

export interface Category {
  title: string;
  fileName: string;
  type: CategoryType;
  items: CategoryItemShow[];
}
