import classNames from "classnames";
import { useMemo } from "react";
//import { CollectionType } from "../../types/CollectionType"
//import { AmenityType } from "../../types/AmenityType"
import { Area as _Area } from "../../types/Area";
import { AreaPosition } from "../../types/AreaPosition";
import { Building } from "../../types/Building";
import { CategoryAPIType, CategoryType } from "../../types/Category";
import { OutdoorSpace } from "../../types/OutdoorSpace";
import { Parking } from "../../types/Parking";
import { getCollectionTypeFromCategoryName } from "../../utils/amenityUtil";
//import { getCollectionTypeFromCategoryName } from "../../utils/amenityUtil"
import { getAreaPosition } from "../../utils/getAreaPosition";
import { AreaSvg } from "../AreaSvg";
import { IconCategoryBar } from "../IconCategoryBar";
import "./styles.css";

interface Props {
  area: _Area;
  active: boolean;
  allActive: boolean;
}

export const Area = ({ area, active, allActive }: Props) => {
  const areaPosition = useMemo(() => getAreaPosition(area), [area]);
  if (!areaPosition) {
    return null;
  }

  let id = area.id;
  const amenityCategoriesSet: Set<CategoryType> = new Set();
  if (area.category_name === CategoryAPIType.BUILDINGS) {
    id = area.name;
    const building = area as Building;
    building.amenities
      .flatMap((a) => a.category_name)
      .filter(
        (category_name) =>
          category_name !== CategoryAPIType.OUTDOOR &&
          category_name !== CategoryAPIType.BIKE_PARKING &&
          category_name !== CategoryAPIType.SMOKING,
      )
      .forEach((category_name: string) => {
        const index = getCollectionTypeFromCategoryName(category_name);
        if (index !== "") {
          amenityCategoriesSet.add(CategoryType[index]);
        }
      });

    if (building.is_leasable) {
      amenityCategoriesSet.add(CategoryType["leasing"]);
    }
  }
  switch (area.category_name) {
    case CategoryAPIType.PARKING:
      const parking = area as Parking;
      id = parking.name;
      amenityCategoriesSet.add(CategoryType["parking"]);
      break;
    case CategoryAPIType.OUTDOOR:
      const outdoor = area as OutdoorSpace;
      id = outdoor.name;
      amenityCategoriesSet.add(CategoryType["outdoor-space"]);
      outdoor.amenities
        .flatMap((a) => a.category_name)
        .forEach((category_name: string) => {
          const index = getCollectionTypeFromCategoryName(category_name);
          if (index !== "") {
            amenityCategoriesSet.add(CategoryType[index]);
          }
        });
      break;
    case CategoryAPIType.SMOKING:
      amenityCategoriesSet.add(CategoryType["smoking-area"]);
      break;
    case CategoryAPIType.BIKE_PARKING:
      amenityCategoriesSet.add(CategoryType["bike-sharing"]);
      break;
    default:
      break;
  }

  const className = classNames("Area", id, {
    "-active": active,
    "-allActive": allActive,
  });

  const _active = active || allActive;
  const amenityCategories = [...amenityCategoriesSet];

  return (
    <div className={className} style={areaPositionToAreaStyle(areaPosition)}>
      {id && <AreaSvg id={id} active={_active} />}
      {_active && (
        <div className="Area-icons">
          <IconCategoryBar
            iconBar={areaPosition.iconBar}
            types={amenityCategories}
          />
        </div>
      )}
    </div>
  );
};

const areaPositionToAreaStyle = ({ left, top }: AreaPosition) => ({
  left: `${left}%`,
  top: `${top}%`,
});
