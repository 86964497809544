//import { CollectionType } from "../../../../types/CollectionType"
import { CategoryItemShow } from "../../../../types/CategoryItemShow";
import { isAmenityByCategoryName } from "../../../../utils/amenityUtil";
import { AssetImage } from "../../../AssetImage";
import "./styles.css";

interface Props {
  categoryItemShow: CategoryItemShow;
}

export const Item = ({ categoryItemShow: { show, item } }: Props) => {
  const { category_name } = item;
  const {
    description,
    address,
    hours,
    orderOptions,
    parking,
    buildings,
    floor,
    menuHref,
    website,
    squareFootage,
    leasingInfo,
  } = show;
  return (
    <div className="Item">
      {description && <p>{description}</p>}
      {buildings && isAmenityByCategoryName(category_name) && (
        <p>{buildings}</p>
      )}
      {address && (
        <p className="Item-paragraph -alignTop">
          <AssetImage fileName="Marker.svg" />
          <span>
            {address}
            {floor && <> • {floor} floor</>}
          </span>
        </p>
      )}
      {hours && (
        <p>
          <>
            <AssetImage fileName="Clock.svg" />
            Today - {hours}
          </>
        </p>
      )}
      {orderOptions && (
        <p>
          <AssetImage fileName="Squares.svg" />
          {orderOptions}
        </p>
      )}
      {parking && (
        <p>
          <AssetImage fileName="Parking.svg" />
          {parking}
        </p>
      )}
      {squareFootage && (
        <p>
          <AssetImage fileName="ruler.svg" />
          {squareFootage}
        </p>
      )}
      {leasingInfo && (
        <p>
          <AssetImage fileName="Download.svg" />
          <a href={leasingInfo}>Download PDF</a>
        </p>
      )}
      {menuHref && (
        <p>
          <AssetImage fileName="Clipboard.svg" />
          <a href={menuHref}>Menu</a>
        </p>
      )}
      {website && (
        <p>
          <AssetImage fileName="Globe.svg" />
          <a href={website}>Website</a>
        </p>
      )}
      {orderOptions?.includes("Order Online") && (
        <p>
          <a href={menuHref}>
            <button>Order Online</button>
          </a>
        </p>
      )}
    </div>
  );
};
