import { Category } from "../types/Category";
import { CategoryItemShow } from "../types/CategoryItemShow";
import { AssetImage } from "./AssetImage";
import { SubMenu } from "./SubMenu";

const menuItemWrapperStyle = {
  color: "black",
  backgroundColor: "white",

  fontFamily: "'Roboto', sans-serif",
  height: "64px",
  display: "flex",
  alignItems: "center",
  padding: "0 0 0 16px",
  margin: "0px",
  justifyContent: "space-between",
};

const menuItemheaderStyle = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
  margin: "0px 8px",
  fontFamily: "'Roboto', sans-serif",
};

const iconStyle = {
  width: "32px",
  height: "32px",
};

const inactiveIconStyle = {
  ...iconStyle,
  filter: "grayscale(1) brightness(.6) contrast(1000%)",
  opacity: ".5",
};

const chevronStyle = {
  color: "#C2C9D1",
  margin: "0px 30px 0px 0px",
  transition: "all ease-in-out 300ms",
};

interface Props {
  data: Category;
  isActive: boolean;
  onClickCategory: (category: Category) => void;
  onClickCategoryItem: (item: CategoryItemShow) => void;
  aMenuIsActive: boolean;
}

export function MenuItem({
  data,
  isActive,
  onClickCategory,
  onClickCategoryItem,
  aMenuIsActive,
}: Props) {
  const lineStyle = {
    opacity: 0.5,
    borderBottom: "1px solid #CCCCCC",
    width: "100%",
    height: "0px",
    padding: "0px",
    margin: "0px",
  };

  return (
    <div>
      <div style={menuItemWrapperStyle} onClick={() => onClickCategory(data)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AssetImage
            style={!aMenuIsActive || isActive ? iconStyle : inactiveIconStyle}
            fileName={data.fileName}
          />
          <h1 style={menuItemheaderStyle}>{data.title}</h1>
        </div>
        <i
          className={
            isActive ? "fas fa-chevron-up" : "fas fa-chevron-up fa-rotate-180"
          }
          style={chevronStyle}
        ></i>
      </div>
      <div style={lineStyle} />
      <SubMenu
        data={data}
        showSubMenu={isActive}
        fileName={data.fileName}
        subMenuClick={(e) => onClickCategoryItem(e)}
      />
    </div>
  );
}
