import ordinal from "ordinal";
import { CategoryAPIType } from "../../types/Category";
import { CategoryItemShow } from "../../types/CategoryItemShow";
import "./styles.css";

interface Props {
  categoryItemShow: CategoryItemShow;
  subMenuClick: (item: CategoryItemShow) => void;
  fileName: string;
}

export function SubMenuItem({ categoryItemShow, subMenuClick }: Props) {
  const { category_name } = categoryItemShow.item;
  const {
    open24Hours,
    isOpen,
    hours,
    leasable,
    floor,
    title,
    buildings,
    parking,
    squareFootage,
    type,
  } = categoryItemShow.show;

  const hasHoursOnCurrentDay = () => {
    if (hours) {
      const day = new Date().getDay();
      const currentDayHours = hours[day];
      if (currentDayHours && currentDayHours.hours.length > 0) {
        return true;
      }
    }

    return false;
  };
  const renderOpen = () => {
    if (open24Hours === true) {
      return <li style={{ color: "#669601" }}>Open</li>;
    } else if (hours) {
      if (isOpen) {
        return <li style={{ color: "#669601" }}>Open</li>;
      }

      return <li style={{ color: "red" }}>Closed</li>;
    }

    return <li style={{ color: "red" }}>Closed</li>;
  };

  const renderHours = () => {
    if (hasHoursOnCurrentDay() && hours) {
      const day = new Date().getDay();
      const currentDayHours = hours[day];
      if (currentDayHours !== undefined) {
        return (
          <li>
            {currentDayHours.hours.map((range: string) => range).join(" & ")}
          </li>
        );
      }
      return null;
    } else if (open24Hours === true) {
      return <li>24 Hours</li>;
    }
    return null;
  };

  const showHours =
    hours !== undefined &&
    !(category_name === CategoryAPIType.BUILDINGS && leasable) &&
    !(category_name === CategoryAPIType.BIKE_PARKING);

  const showLeasingInfo =
    category_name === CategoryAPIType.BUILDINGS && leasable;

  const showFloor = Boolean(floor && parseInt(floor) > 0);

  const showFloorBuildingParking = (floor && showFloor) || buildings || parking;

  return (
    <div className="SubMenuItem" onClick={() => subMenuClick(categoryItemShow)}>
      <h1>{title}</h1>
      {showFloorBuildingParking && (
        <ul>
          {type && <li>{type}</li>}
          {floor && showFloor && <li>{ordinal(parseInt(floor))} Floor</li>}
          {buildings && <li>{buildings}</li>}
          {parking && <li>{parking}</li>}
        </ul>
      )}
      {showHours && (
        <ul>
          {renderOpen()}
          {(hours || open24Hours) && renderHours()}
        </ul>
      )}
      {showLeasingInfo && (
        <ul>
          <li className="-spaceAvailable">Space Available</li>
          {squareFootage && <li>{squareFootage}</li>}
        </ul>
      )}
    </div>
  );
}
