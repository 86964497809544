import { useMemo } from "react";
import { Amenity } from "../types/Amenity";
import { Building } from "../types/Building";
import { CategoryAPIType, CategoryType } from "../types/Category";
import { CategoryMap } from "../types/CategoryMap";
import { OutdoorSpace } from "../types/OutdoorSpace";
import { Parking } from "../types/Parking";
import {
  getCollectionTypeFromCategoryName,
  isAmenityByCategoryName,
} from "../utils/amenityUtil";
import { categoryItemToShow } from "../utils/categoryItemToShow";

interface Props {
  buildings: Building[];
  amenities: Amenity[];
  outdoorSpaces: OutdoorSpace[];
  parking: Parking[];
}

export const useCategories = ({
  buildings,
  amenities,
  outdoorSpaces,
  parking,
}: Props) => {
  const categories = useMemo(() => {
    const menuItems = [
      ...buildings,
      ...amenities,
      ...outdoorSpaces,
      ...parking,
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    const categoryMap = menuItems.reduce((a, b) => {
      if (isAmenityByCategoryName(b.category_name)) {
        const category = a[getCollectionTypeFromCategoryName(b.category_name)];

        if (category) {
          const show = categoryItemToShow({
            category,
            item: b,
          });
          if (show) {
            category.items.push(show);
          }
        }
      }
      if (b.category_name === CategoryAPIType.BUILDINGS) {
        const building = b as Building;
        if (building.is_leasable) {
          const category = a["leasing"];
          if (category) {
            const show = categoryItemToShow({
              category,
              item: b,
            });
            if (show) {
              category.items.push(show);
            }
          }
        }
      }
      if (b.category_name === CategoryAPIType.PARKING) {
        const category = a["parking"];
        if (category) {
          const show = categoryItemToShow({
            category,
            item: b,
          });
          if (show) {
            category.items.push(show);
          }
        }
      }
      if (b.category_name === CategoryAPIType.OUTDOOR) {
        const category = a["outdoor-space"];
        if (category) {
          const show = categoryItemToShow({
            category,
            item: b,
          });
          if (show) {
            category.items.push(show);
          }
        }
      }
      if (b.category_name === CategoryAPIType.BIKE_PARKING) {
        const category = a["bike-sharing"];
        if (category) {
          const show = categoryItemToShow({
            category,
            item: b,
          });
          if (show) {
            category.items.push(show);
          }
        }
      }
      if (b.category_name === CategoryAPIType.SMOKING) {
        const category = a["smoking-area"];
        if (category) {
          const show = categoryItemToShow({
            category,
            item: b,
          });
          if (show) {
            category.items.push(show);
          }
        }
      }
      return a;
    }, makeCategoryMap());
    return Object.values(categoryMap);
  }, [buildings, amenities, outdoorSpaces, parking]);

  return { categories };
};

const makeCategoryMap = (): CategoryMap => ({
  [CategoryType.atms]: {
    title: "ATMs",
    fileName: "AmenitiesIconsATMs.svg",
    type: CategoryType.atms,
    items: [],
  },
  [CategoryType["bike-sharing"]]: {
    title: "Bike Sharing",
    fileName: "AmenitiesIconsBikeParking.svg",
    type: CategoryType["bike-sharing"],
    items: [],
  },
  [CategoryType["coffee-cafe"]]: {
    title: "Coffee Cafes",
    fileName: "AmenitiesIconsCafe.svg",
    type: CategoryType["coffee-cafe"],
    items: [],
  },
  [CategoryType["conference-center"]]: {
    title: "Conference Centers",
    fileName: "AmenitiesIconsConferenceCenters.svg",
    type: CategoryType["conference-center"],
    items: [],
  },
  [CategoryType.dining]: {
    title: "Dining",
    fileName: "AmenitiesIconsDining.svg",
    type: CategoryType.dining,
    items: [],
  },
  [CategoryType["health-fitness"]]: {
    title: "Fitness Center",
    fileName: "AmenitiesIconsFitness.svg",
    type: CategoryType["health-fitness"],
    items: [],
  },
  [CategoryType["leasing"]]: {
    title: "Leasing Space Available",
    fileName: "AmenitiesIconsLeasingSpaceAvailable.svg",
    type: CategoryType["leasing"],
    items: [],
  },
  [CategoryType["management-office"]]: {
    title: "Management and Leasing",
    fileName: "AmenitiesIconsManagementOffice.svg",
    type: CategoryType["management-office"],
    items: [],
  },
  [CategoryType["outdoor-space"]]: {
    title: "Outdoor Spaces",
    fileName: "AmenitiesIconsOutdoorSpace.svg",
    type: CategoryType["outdoor-space"],
    items: [],
  },
  [CategoryType["smoking-area"]]: {
    title: "Smoking Areas",
    fileName: "AmenitiesIconsSmokingArea.svg",
    type: CategoryType["smoking-area"],
    items: [],
  },
  [CategoryType["parking"]]: {
    title: "Parking",
    fileName: "AmenitiesIconsParking.svg",
    type: CategoryType["parking"],
    items: [],
  },
  [CategoryType["retail-services"]]: {
    title: "Retail & Services",
    fileName: "AmenitiesIconsRetailandServices.svg",
    type: CategoryType["retail-services"],
    items: [],
  },
  [CategoryType["studio"]]: {
    title: "Studio",
    fileName: "AmenitiesIconsAspiriaStudioIcon.svg",
    type: CategoryType.studio,
    items: [],
  },
});
