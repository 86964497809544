import { RefObject } from "react";
import "./styles.css";

interface Props {
  innerRef?: RefObject<HTMLImageElement>;
  fileName: string;
  style?: React.CSSProperties;
}

export const AssetImage = (props: Props) => (
  <img
    className="AssetImage"
    alt=""
    ref={props.innerRef}
    src={`${process.env.PUBLIC_URL}/images/${props.fileName}`}
    style={props.style}
  />
);
