import { AreaPosition } from "../../types/AreaPosition";
import { CategoryType } from "../../types/Category";
import { IconCategory } from "../IconCategory";
import "./styles.css";

interface Props {
  types: CategoryType[];
  iconBar: AreaPosition["iconBar"];
}

export function IconCategoryBar({ types, iconBar }: Props) {
  return (
    <div className="IconCategoryBar" style={iconBarToAreaIconsStyle(iconBar)}>
      {types.map((type) => (
        <IconCategory key={type} type={type} iconBar={iconBar} />
      ))}
    </div>
  );
}

const iconBarToAreaIconsStyle = ({
  left,
  top,
  rotation,
  styles,
}: AreaPosition["iconBar"]): React.CSSProperties => {
  return {
    left: `${left}px`,
    top: `${top}px`,
    transform: `rotate(${rotation}deg)`,
    ...styles,
  };
};
