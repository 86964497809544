import { ReactNode, createContext, useState } from "react";

export type MenuMobileState =
  | { type: "CATEGORIES_INACTIVE" }
  | { type: "CATEGORIES_ACTIVE" }
  | { type: "CATEGORY_FULL" }
  | { type: "CATEGORY_PARTIAL" }
  | { type: "ITEM_FULL" }
  | { type: "ITEM_PARTIAL" };

export const MenuMobileContext = createContext<{
  state: MenuMobileState;
  dispatch: React.Dispatch<React.SetStateAction<MenuMobileState>>;
}>({
  state: { type: "CATEGORIES_INACTIVE" },
  dispatch: () => {
    "CATEGORIES_INACTIVE";
  },
});

export const MenuMobileProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useState<MenuMobileState>({
    type: "CATEGORIES_INACTIVE",
  });

  const value = { state, dispatch };
  return (
    <MenuMobileContext.Provider value={value}>
      {children}
    </MenuMobileContext.Provider>
  );
};
