import { useCallback, useState } from "react";
import { MenuState } from "../types/MenuState";

const initialState = {
  selectedCategory: null,
  selectedCategoryItem: null,
};

export const useMenuState = () => {
  const [current, setCurrent] = useState<MenuState>(initialState);

  const reset = useCallback(() => setCurrent(initialState), []);

  return { current, setCurrent, reset };
};
