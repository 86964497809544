import axios from "axios";
import { useEffect, useState } from "react";
import { Amenity } from "../types/Amenity";

export const useAmenities = () => {
  const [amenities, setAmenities] = useState<Amenity[]>([]);

  const nodeServerBaseUrl =
    process.env.REACT_APP_NODE_SERVER_BASE_URL ||
    "https://aspiria-prod-api.azurewebsites.net/api/v1";

  useEffect(() => {
    axios
      .get(`${nodeServerBaseUrl}/amenity/`)
      .then((response) => {
        setAmenities(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nodeServerBaseUrl]);

  return {
    amenities,
  };
};
