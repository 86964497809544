/* eslint-disable import/order */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuMobileContext } from "../../contexts/MenuMobileContext";
import { useAmenities } from "../../hooks/useAmenities";
import { useBuildings } from "../../hooks/useBuildings";
import { useCategories } from "../../hooks/useCategories";
import { useOutdoorSpaces } from "../../hooks/useOutdoorSpaces";
import { useParking } from "../../hooks/useParking";
import { useMenuState } from "../../hooks/useMenuState";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Selected } from "../../types/Selected";
import { CategoryAPIType, CategoryType } from "../../types/Category";
import { CategoryItemShow } from "../../types/CategoryItemShow";
import { calculateCenter } from "../../utils/calculateCenter";
import { clamp } from "../../utils/clamp";
import { Area } from "../Area";
import { AssetImage } from "../AssetImage";
import { Menu } from "../Menu";
import { MenuMobile } from "../MenuMobile";
import { TooltipCard } from "../TooltipCard";
import "./styles.css";
import { Building } from "../../types/Building";
import { Amenity } from "../../types/Amenity";
import { OutdoorSpace } from "../../types/OutdoorSpace";
import { Parking } from "../../types/Parking";
import { isAmenityByCategoryName } from "../../utils/amenityUtil";

export function App() {
  const { buildings } = useBuildings();

  const { amenities } = useAmenities();

  const { parking } = useParking();

  const { outdoorSpaces } = useOutdoorSpaces();

  const areas = useMemo(() => {
    return [...amenities, ...buildings, ...outdoorSpaces, ...parking];
  }, [amenities, buildings, outdoorSpaces, parking]);

  const { categories } = useCategories({
    buildings,
    amenities,
    outdoorSpaces,
    parking,
  });

  const [selected, setSelected] = useState<Selected>({
    categoryIds: [],
    itemId: null,
  });
  const { dispatch: menuMobileDispatch } = useContext(MenuMobileContext);
  const menuState = useMenuState();
  const selectedCategory = menuState.current.selectedCategory;
  const selectedCategoryItem = menuState.current.selectedCategoryItem;

  const [showCard, setShowCard] = useState(false);
  const [cardInfo, setCardInfo] = useState<CategoryItemShow | null>(null);
  const mapWrapperRef = useRef<HTMLDivElement | null>(null);
  const mapWrapperWidth = mapWrapperRef?.current?.getBoundingClientRect().width;
  const mapWrapperHeight =
    mapWrapperRef?.current?.getBoundingClientRect().height;

  const mapRef = useRef<HTMLDivElement | null>(null);
  const mapWidth = mapRef?.current?.clientWidth;
  const mapHeight = mapRef?.current?.clientHeight;

  const zoomLevelMax = 1.4;
  const zoomLevelMin = 0.6;
  const zoomLevelInitial = 0.8;
  const [zoomLevel, setZoomLevel] = useState(zoomLevelInitial);
  const [clicking, setClicking] = useState(false);
  const [prevX, setPrevX] = useState(0);
  const [prevY, setPrevY] = useState(0);
  const [transformX, setTransformX] = useState(0);
  const [transformXInitial, setTransformXInitial] = useState(0);
  const [transformY, setTransformY] = useState(0);
  const [transformYInitial, setTransformYInitial] = useState(0);
  const [lastReset, setLastReset] = useState(new Date());
  const [mapShouldTransition, setMapShouldTransition] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (mapWrapperWidth && mapWidth && mapWrapperHeight && mapHeight) {
      const x = calculateCenter(mapWidth, mapWrapperWidth);
      const y = calculateCenter(mapHeight, mapWrapperHeight);
      setTransformX(x);
      setTransformXInitial(x);
      setTransformY(y);
      setTransformYInitial(y);
    }
    setTimeout(() => setMapShouldTransition(false), 300);
  }, [mapWrapperWidth, mapWrapperHeight, mapWidth, mapHeight, lastReset]);

  useEffect(() => {
    const category = selectedCategory;
    setSelected((current) => ({ ...current, categoryIds: [] }));
    if (
      category &&
      category.type !== CategoryType["parking"] &&
      category.type !== CategoryType["bike-sharing"]
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ids = category.items.reduce((acc: any, x: any) => {
        if (isAmenityByCategoryName(x.item.category_name)) {
          if (x.item.buildings) {
            acc = acc.concat(
              x.item.buildings.map((building: Building) => building.id),
            );
          }
        }

        if (x.item.category_name === CategoryAPIType.PARKING) {
          if (x.item.buildings) {
            acc = acc.concat(
              x.item.buildings.map((building: Building) => building.id),
            );
          }
        }

        if (x.item.category_name === CategoryAPIType.BUILDINGS) {
          acc.push(x.item.id);
        }

        return acc;
      }, []);
      setSelected((current) => ({ ...current, categoryIds: ids }));
    }

    if (
      category &&
      (category.type === CategoryType["parking"] ||
        category.type === CategoryType["outdoor-space"] ||
        category.type === CategoryType["bike-sharing"] ||
        category.type === CategoryType["smoking-area"])
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const categoryIds = category.items.map((x: any) => x.item.id);
      setSelected((current) => ({ ...current, categoryIds }));
    }

    if (!selectedCategoryItem) {
      setShowCard(false);
    }

    if (!selectedCategory) {
      setSelected({ itemId: null, categoryIds: [] });
      setShowCard(false);
    }
  }, [outdoorSpaces, selectedCategory, selectedCategoryItem]);

  useEffect(() => {
    const selectedItemId = selectedCategoryItem?.item.id;
    const relatedAreas = areas.filter((a) => {
      if (a.category_name === CategoryAPIType.BUILDINGS) {
        const building = a as Building;
        return (
          building.id === selectedItemId ||
          building.amenities.some((x: Amenity) => {
            return x.id === selectedItemId;
          })
        );
      }
      if (a.category_name === CategoryAPIType.OUTDOOR) {
        const outdoor = a as OutdoorSpace;
        return (
          outdoor.id === selectedItemId ||
          outdoor.amenities.some((x: Amenity) => x.id === selectedItemId)
        );
      }
      if (a.category_name === CategoryAPIType.BIKE_PARKING) {
        return a.id === selectedItemId;
      }
      if (a.category_name === CategoryAPIType.PARKING) {
        return a.id === selectedItemId;
      }
      return false;
    });

    const relatedIds = relatedAreas.reduce<string[]>((a, b) => {
      if (b.category_name === CategoryAPIType.BUILDINGS) {
        const building = b as Building;
        a = a.concat(building.parking.map((x) => x.id));
        a = a.concat(
          building.amenities.map((x) => {
            if (x.category_name === CategoryAPIType.BIKE_PARKING) {
              return x.id;
            }
            return "";
          }),
        );
      }
      if (b.category_name === CategoryAPIType.OUTDOOR) {
        const outdoor = b as OutdoorSpace;
        a = a.concat(outdoor.parking.map((x) => x.id));
      }
      if (b.category_name === CategoryAPIType.PARKING) {
        const parking = b as Parking;
        if (parking.buildings) {
          a = a.concat(parking.buildings.map((x) => x.id));
        }
      }
      return a;
    }, []);

    const ids = relatedAreas.map((x) => {
      return x.id;
    });
    const finalIds = [...ids, ...relatedIds];

    if (selectedItemId) {
      setSelected({
        categoryIds: finalIds,
        itemId: selectedItemId,
      });
    }

    setCardInfo(selectedCategoryItem);
    setShowCard(true);
  }, [areas, selectedCategoryItem]);

  useEffect(() => {
    const appPrimary = document
      .querySelectorAll(".App-primary")[0]
      ?.getBoundingClientRect();

    const menuMobilePanel = document
      .querySelectorAll(".MenuMobile-panel.-availableSpace")[0]
      ?.getBoundingClientRect();

    const bottom =
      appPrimary && menuMobilePanel
        ? appPrimary.bottom > menuMobilePanel.top
          ? menuMobilePanel.top
          : appPrimary.bottom
        : appPrimary
        ? appPrimary.bottom
        : 0;

    const availableSpace = appPrimary
      ? {
          top: appPrimary.top,
          right: appPrimary.right,
          bottom,
          left: appPrimary.left,
          centerX: appPrimary.right - (appPrimary.right - appPrimary.left) / 2,
          centerY: bottom - (bottom - appPrimary.top) / 2,
        }
      : { top: 0, right: 0, bottom: 0, left: 0, centerX: 0, centerY: 0 };

    const activeAreas = document.querySelectorAll(".Area.-active");
    const active = [...activeAreas].reduce<{
      top: number;
      right: number;
      bottom: number;
      left: number;
      centerX: number;
      centerY: number;
    } | null>((a, b, i) => {
      const {
        top: _top,
        right: _right,
        bottom: _bottom,
        left: _left,
      } = b.getBoundingClientRect();
      const top = i > 0 && a && a.top < _top ? a.top : _top;
      const right = i > 0 && a && a.right > _right ? a.right : _right;
      const bottom = i > 0 && a && a.bottom > _bottom ? a.bottom : _bottom;
      const left = i > 0 && a && a.left < _left ? a.left : _left;
      const centerX = right - (right - left) / 2;
      const centerY = bottom - (bottom - top) / 2;
      return { top, right, bottom, left, centerX, centerY };
    }, null);

    // Center within available space
    if (active) {
      setMapShouldTransition(true);

      const moveX = availableSpace.centerX - active.centerX;
      setTransformX((x) => x + moveX);

      const moveY = availableSpace.centerY - active.centerY;
      setTransformY((y) => y + moveY);

      setTimeout(() => setMapShouldTransition(false), 300);
    }
  }, [selected]);

  const mapCanvasTransformStyles = useMemo(
    () => ({
      transform: `translate(${transformX}px, ${transformY}px)`,
      ...(mapShouldTransition
        ? {
            transition: "transform 300ms ease-in-out",
          }
        : {}),
    }),
    [mapShouldTransition, transformX, transformY],
  );

  const mapCanvasZoomTransformStyles = useMemo(
    () => ({
      transform: `scale(${zoomLevel})`,
    }),
    [zoomLevel],
  );

  function handleCloseCard() {
    setShowCard(false);
    menuState.setCurrent((current) => ({
      ...current,
      selectedCategoryItem: null,
    }));
  }

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (clicking) {
        const mapRect = mapRef.current?.getBoundingClientRect();
        const wrapperRect = mapWrapperRef.current?.getBoundingClientRect();

        if (wrapperRect && mapRect) {
          const wrapperWidth = wrapperRect.width;

          let dX = prevX - e.clientX;
          let dY = prevY - e.clientY;

          if (dX > 0 && mapRect.right < wrapperRect.right) {
            dX = 0;
          }

          if (dY > 0 && mapRect.bottom < wrapperRect.bottom) {
            dY = 0;
          }

          const xLowerBound = (wrapperWidth - mapRect.width) * zoomLevel;
          const yLowerBound = (wrapperRect.height - mapRect.height) * zoomLevel;

          const maxX = -((mapRect.width - mapRect.width * zoomLevel) / 2);
          const maxY = -((mapRect.height - mapRect.height * zoomLevel) / 2);

          const newX = clamp(transformX - dX, xLowerBound, maxX);
          const newY = clamp(transformY - dY, yLowerBound, maxY);

          setTransformX(newX);
          setTransformY(newY);

          setPrevX(e.clientX);
          setPrevY(e.clientY);
        }
      }
    },
    [clicking, prevX, prevY, transformX, transformY, zoomLevel],
  );

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (clicking) {
        const mapRect = mapRef.current?.getBoundingClientRect();
        const wrapperRect = mapWrapperRef.current?.getBoundingClientRect();
        const touch = e.touches[0];

        if (wrapperRect && mapRect && touch) {
          const wrapperWidth = wrapperRect.width;

          let dX = prevX - touch.clientX;
          let dY = prevY - touch.clientY;

          if (dX > 0 && mapRect.right < wrapperRect.right) {
            dX = 0;
          }

          if (dY > 0 && mapRect.bottom < wrapperRect.bottom) {
            dY = 0;
          }

          const xLowerBound = (wrapperWidth - mapRect.width) * zoomLevel;
          const yLowerBound = (wrapperRect.height - mapRect.height) * zoomLevel;

          const maxX = -((mapRect.width - mapRect.width * zoomLevel) / 2);
          const maxY = -((mapRect.height - mapRect.height * zoomLevel) / 2);

          const newX = clamp(transformX - dX, xLowerBound, maxX);
          const newY = clamp(transformY - dY, yLowerBound, maxY);

          setTransformX(newX);
          setTransformY(newY);

          setPrevX(touch.clientX);
          setPrevY(touch.clientY);
        }
      }
    },
    [clicking, prevX, prevY, transformX, transformY, zoomLevel],
  );

  const handleZoomIn = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (zoomLevel < zoomLevelMax) {
        setZoomLevel(Number((zoomLevel + 0.2).toFixed(2)));
      }
    },
    [zoomLevel],
  );

  const handleZoomOut = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (zoomLevel > zoomLevelMin) {
        setZoomLevel(Number((zoomLevel - 0.2).toFixed(2)));
      }
    },
    [zoomLevel],
  );

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPrevX(e.clientX);
    setPrevY(e.clientY);
    setClicking(true);
  }, []);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const touch = e.touches[0];
      if (touch) {
        setPrevX(touch.clientX);
        setPrevY(touch.clientY);
        setClicking(true);
      }
    },
    [],
  );

  const handleMouseUp = useCallback(() => {
    setClicking(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setClicking(false);
  }, []);

  const handleReset = useCallback(() => {
    setMapShouldTransition(true);
    menuState.reset();
    setZoomLevel(zoomLevelInitial);
    setLastReset(new Date());
  }, [menuState]);

  const handleAppHeaderMobileClose = () => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage("closeMap", "*");
    menuState.reset();
    setZoomLevel(zoomLevelInitial);
    menuMobileDispatch({ type: "CATEGORIES_INACTIVE" });
    setLastReset(new Date());
  };

  return (
    <div className="App">
      {width && width > 991 ? null : (
        <div className="AppHeader-mobile">
          <div>Explore Aspiria</div>
          <div onClick={handleAppHeaderMobileClose}>
            <AssetImage fileName="Close.svg" />
          </div>
        </div>
      )}
      <div className="App-secondary">
        {width && width > 991 ? (
          <>
            <Menu menuState={menuState} categories={categories} />
            {cardInfo && (
              <TooltipCard
                categoryItemShow={cardInfo}
                showCard={showCard}
                onClick={handleCloseCard}
              />
            )}
          </>
        ) : (
          <MenuMobile
            menuState={menuState}
            categories={categories}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            zoomLevel={zoomLevel}
            zoomLevelMax={zoomLevelMax}
            zoomLevelMin={zoomLevelMin}
          />
        )}
      </div>
      <div
        className="App-primary"
        onMouseMove={handleMouseMove}
        onTouchMove={handleTouchMove}
        onMouseLeave={handleMouseLeave}
        ref={mapWrapperRef}
      >
        {width && width > 991 && (
          <div className="Zoom">
            <button
              disabled={zoomLevel === zoomLevelMax}
              onClick={handleZoomIn}
              className="on-map-buttons"
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              disabled={zoomLevel === zoomLevelMin}
              onClick={handleZoomOut}
              className="on-map-buttons"
            >
              <i className="fas fa-minus"></i>
            </button>
          </div>
        )}
        <div className="map-button-wrapper position-map-top-left flex-row">
          <button
            disabled={
              !menuState.current.selectedCategoryItem &&
              !menuState.current.selectedCategory &&
              zoomLevel === zoomLevelInitial &&
              transformX === transformXInitial &&
              transformY === transformYInitial
            }
            onClick={handleReset}
            className="on-map-buttons -reset"
          >
            <i className="fas fa-redo"></i>
          </button>
        </div>

        <div
          ref={mapRef}
          className="mapCanvas"
          style={mapCanvasTransformStyles}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleMouseUp}
        >
          <div className="mapCanvas-zoom" style={mapCanvasZoomTransformStyles}>
            <img
              className="mapCanvas-map"
              alt="Aspiria Campus Map"
              src={`${process.env.PUBLIC_URL}/images/map.png`}
              width={2800}
              height={1980}
            />
            <img
              className="mapCanvas-sidewalks"
              alt="Aspiria Campus Sidewalks"
              src={`${process.env.PUBLIC_URL}/images/sidewalks.svg`}
            />
            {areas.map((area) => {
              const isActive = selected.categoryIds.includes(area.id);
              const allActive = selected.categoryIds.length === 0;
              return (
                <Area
                  key={area.id}
                  area={area}
                  active={isActive}
                  allActive={allActive}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
