import { Category } from "../types/Category";
import { CategoryItemShow } from "../types/CategoryItemShow";
import { SubMenuItem } from "./SubMenuItem";

interface Props {
  data: Category;
  fileName: string;
  showSubMenu: boolean;
  subMenuClick: (item: CategoryItemShow) => void;
}

export function SubMenu({ data, fileName, showSubMenu, subMenuClick }: Props) {
  const lineStyle = {
    opacity: 0.5,
    borderBottom: "1px solid #CCCCCC",
    overflow: "hidden",
    height: "0px",
    padding: "0px",
  };

  const BottomLineStyle = {
    opacity: 0.5,
    borderBottom: "1px solid #CCCCCC",
    overflow: "hidden",
    height: "0px",
    margin: "0px",
  };

  const renderBottomLine = (items: CategoryItemShow[], index: number) => {
    if (items[items.length - 1] === items[index]) {
      return <div style={BottomLineStyle} />;
    } else {
      return <div style={lineStyle} />;
    }
  };

  const menuWrapperStyle = {
    color: "black",
    fontFamily: "'Roboto', sans-serif",
    width: "100%",
    overflow: "auto",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return (
    <div id="wrapper">
      {data.items.map((categoryItemShow, index) => {
        return (
          <div
            key={categoryItemShow.item.id}
            style={{
              ...menuWrapperStyle,
              maxHeight: showSubMenu ? `124px` : "0px",
              opacity: showSubMenu ? "1" : "0",
              transition: "all ease-in-out 300ms",
              color: "black",
              marginLeft: "auto",
              marginRight: "auto",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <SubMenuItem
              fileName={fileName}
              categoryItemShow={categoryItemShow}
              subMenuClick={(e) => subMenuClick(e)}
            />
            {renderBottomLine(data.items, index)}
          </div>
        );
      })}
    </div>
  );
}
