import axios from "axios";
import { useEffect, useState } from "react";
import { Building } from "../types/Building";

export const useBuildings = () => {
  const [buildings, setBuildings] = useState<Building[]>([]);

  const nodeServerBaseUrl =
    process.env.REACT_APP_NODE_SERVER_BASE_URL ||
    "https://aspiria-prod-api.azurewebsites.net/api/v1";

  useEffect(() => {
    axios
      .get(`${nodeServerBaseUrl}/building/`)
      .then((response) => {
        setBuildings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nodeServerBaseUrl]);

  return {
    buildings,
  };
};
